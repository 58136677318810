
const nav_dropdown = () => {

    function show_hide() {
        const parent = this.closest('.center-nav-li_with-subnav');
        if (parent.classList.contains('opened')) {
            parent.classList.remove('opened');
        } else {
            parent.classList.add('opened');
        }
    }

    document.querySelectorAll('.center-nav-li_with-subnav .center-nav-link').forEach((x) => {x.addEventListener('click', show_hide)});
}

export default nav_dropdown;
