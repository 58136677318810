import debounce from 'lodash/debounce';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
const Swiper = SwiperCore.use([Navigation, Pagination]);

const TABLET = 768;
let container, swiper;


const news_slider = () => {
    container = document.querySelector('.swiper-news-slider');

    if (!container) {
        return;
    }

    const window_switcher = () => {
        if (document.documentElement.clientWidth < TABLET) {
            if(!swiper) {
                swiper = new Swiper(container, {
                    slidesPerView: 'auto',
                    spaceBetween: 16,

                    // If we need pagination
                    pagination: {
                        el: '.swiper-pagination',
                        type: 'fraction',
                    },

                    // Navigation arrows
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                });
            }
            return;
        } else if (swiper) {
            swiper.destroy(true, true)
        }
    }


    window_switcher();
    window.addEventListener('resize', debounce(window_switcher, 50));
};

export default news_slider;
