import IMask from '../vendor/imask';
import axios from '../vendor/axios';
import { check_data, post_data, wait } from './utils';
// import { show_popup } from '../popup';


// function post_data(url, data) {
//     const token = document.querySelector('meta[name="csrf-token"]').content;
//     axios.defaults.headers.common['X-CSRF-Token'] = token;
//     axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

//     return new Promise(function(resolve, reject) {
//         axios.post(url, data).then((response) => {
//             resolve(response.data);
//         }).catch((error) => {
//             console.error(error);
//         });
//     });
// }



function profile() {

    document.querySelectorAll('*[data-type="auth"]').forEach((elem) => {
        elem.addEventListener('submit', async (event) => {
            event.preventDefault();
            let self = event.target;
            let stderr, stdout;
            if (check_data(self)) {
                wait(self, true);
                let result = await post_data(self);
                if (result.status === 'ok') {
                    if (result.hasOwnProperty('redirect_to')) {
                        window.location = result.redirect_to;
                        return;
                    }

                    if (result.hasOwnProperty('slide')) {
                        const msg = document.querySelector(`.tab-${result.slide} .auth-text` );
                        msg.innerHTML = result.message;
                        change_slide(result.slide);
                        return;
                    }

                } else if (result.status === 'fail') {
                    stderr = self.querySelector('.form-line-msg');
                    stderr.innerHTML = result.message;
                }
                wait(self, false);
            }
        });
    });


    // document.querySelectorAll('.button-edit').forEach((elem) => {
    //     elem.addEventListener('click', (event) => {
    //         const self = event.currentTarget;
    //         const node = self.closest('.edit-element');
    //         const input = node.querySelector('input, textarea');
    //         input.removeAttribute('readonly');
    //         input.focus();
    //         node.dataset.mode = 'edit';
    //     });
    // });

    // document.querySelectorAll('.button-save').forEach((elem) => {
    //     elem.addEventListener('click', async (event) => {
    //         const self = event.currentTarget;
    //         const node = self.closest('.edit-element');
    //         const input = node.querySelector('input, textarea');

    //         let local, re;
    //         if (input.dataset.regexp && input.dataset.regexp !== '') {
    //             re = new RegExp(input.dataset.regexp, 'i');
    //             local = re.test(input.value);
    //         } else {
    //             local = input.value !== '';
    //         }

    //         if (local) {
    //             let request = {};
    //             request[input.name] = input.value;
    //             let response = await post_data('/profile/edit', request);
    //             if (response.status === 'ok') {
    //                 input.setAttribute('readonly', 'readonly');
    //                 node.dataset.mode = 'view';
    //             }
    //         }
    //     });
    // });

    document.querySelectorAll('input[name="tel"]').forEach((node) => {
        const maskOptions = {
            mask: '+000 (00) 000-00-00',
            lazy: true,
            placeholderChar: '_',
        };
        IMask(node, maskOptions);
    });

    const edit_birth_date = document.getElementById('edit_birth_date');
    if (edit_birth_date) {
        const maskBirdOptions = {
            mask: '00.00.0000',
            lazy: true,
            placeholderChar: '_',
        };
        IMask(edit_birth_date, maskBirdOptions);
    }

    // show-nav
    const sandwich = document.querySelector('.profile-sandwich .sandwich-btn');

    if (sandwich) {
        sandwich.addEventListener('click', () => {
            document.body.classList.add('show-nav');
        })
    }
}

export default profile;
