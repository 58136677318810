import { check_data, post_data, wait } from './utils';
let container;

function message_form() {

    document.querySelectorAll('*[data-type="message"]').forEach((elem) => {
        elem.addEventListener('submit', async (event) => {
            event.preventDefault();
            let self = event.target;
            let stderr, stdout;
            if (check_data(self)) {
                wait(self, true);
                let result = await post_data(self);
                if (result.status === 'ok') {
                    try {
                        document.querySelector('.message-popup-body').innerHTML = result.message;
                        self.querySelectorAll('input, textarea').forEach((elem) => {
                            elem.value = ''
                        });
                        document.body.classList.add('show-popup-msg-box');
                    } catch(e) {
                        // empty
                    }
                }
                wait(self, false);
            }
        });
    });

    document.querySelectorAll('*[data-link-type="hide-auth"]').forEach((elem) => {
        elem.addEventListener('click', () => {
            document.body.classList.remove('show-popup-msg-box');
        })
    });

    document.querySelectorAll('.footer-combo-input, .footer-combo-textarea').forEach((elem) => {
        if(elem.value !== '') {
            elem.closest('.footer-combo').classList.add('focused');
        }
        elem.addEventListener('focus', () => {
            elem.closest('.footer-combo').classList.add('focused');
        });
        elem.addEventListener('blur', () => {
            if(elem.value == '') {
                elem.closest('.footer-combo').classList.remove('focused');
            }
        });
    });
}

export default message_form;
