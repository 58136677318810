import debounce from 'lodash/debounce';

const TABLET = 768;
const HEADER_HEIGHT = 94;
let viewport_height;

const stickynav = () => {
    let last_position = 0;
    let ticking = false;
    const body = document.body;
    const header = body.querySelector('.mobile-header-body');

    if (!header) {
        return;
    }

    const check_sticky_nav = (new_postition) => {
        console.log(new_postition);
        if (new_postition < last_position) {
            body.classList.remove('mobile-header-hide');
        } else {
            body.classList.add('mobile-header-hide');
        }
        new_postition > HEADER_HEIGHT ? header.classList.add('bg-black') : header.classList.remove('bg-black');

        last_position = new_postition;
    }

    const scroll_listener = () => {
        let new_known_scroll_position = window.scrollY;
        if (!ticking) {
            window.requestAnimationFrame(() => {
                check_sticky_nav(new_known_scroll_position);
                ticking = false;
            });
            ticking = true;
        }
    }

    const window_switcher = () => {
        viewport_height = document.documentElement.clientHeight;
        if (document.documentElement.clientWidth < TABLET) {
            window.addEventListener('scroll', scroll_listener)
        } else {
            window.removeEventListener('scroll', scroll_listener)
        }
    }

    window_switcher();
    window.addEventListener('resize', debounce(window_switcher, 50));
}

export default stickynav;
