let container, player, id, backstage;

const get_quality = () => {
    const wv = document.documentElement.offsetWidth;
    if (wv > 1280) {
        return '1080p';
    }
    if (wv > 854) {
        return '720p';
    }
    return '480p';
};

const init_backstage = (btn) => {
    if (!btn) {
        return;
    }

    backstage = document.getElementById('video-backstage');
    if (!backstage) {
        return;
    }
    backstage.volume = 0.25;

    const quality = get_quality();

    let webm = document.createElement('source');
    webm.setAttribute('type', 'video/webm');
    webm.setAttribute('src', `/video/backstage/backstage_2020_${quality}.webm`);
    backstage.appendChild(webm);

    let mp4 = document.createElement('source');
    mp4.setAttribute('type', 'video/mp4');
    mp4.setAttribute('src', `/video/backstage/backstage_2020_${quality}.mp4`);
    backstage.appendChild(mp4);

    const box = btn.closest('.stories-main');
    const listener = box.querySelector('.video-backstage-poster');

    btn.addEventListener('mouseover', function(){
        box.classList.add('mouseover');
    });

    btn.addEventListener('mouseout', function(){
        box.classList.remove('mouseover');
    });

    btn.addEventListener('click', function(){
        box.classList.remove('mouseover');
        box.classList.add('played');
        backstage.play();
    });

    listener.addEventListener('click', () => {
        if (backstage.paused) {
            backstage.play();
        } else {
            backstage.pause();
        }
    });

    backstage.addEventListener('ended', () => {
        box.classList.remove('played');
        backstage.currentTime = 0;
    });

};

const make_player = () => {
    const video = document.createElement('video');
    const quality = get_quality();

    let webm = document.createElement('source');
    webm.setAttribute('type', 'video/webm');
    webm.setAttribute('src', `${id}_${quality}.webm`);
    video.appendChild(webm);

    let mp4 = document.createElement('source');
    mp4.setAttribute('type', 'video/mp4');
    mp4.setAttribute('src', `${id}_${quality}.mp4`);
    video.appendChild(mp4);

    video.setAttribute('preload', 'auto');
    video.setAttribute('poster', `${id}.png`);
    video.setAttribute('controls', 'controls');
    video.setAttribute('id', 'video-player');
    video.classList.add('video-player');
    return video;
};

function to_start() {
    this.currentTime = 0;
}

const open_video = (event) => {
    const target = event.currentTarget;
    const newid = target.dataset.video;

    if (backstage && !backstage.paused) {
        backstage.pause();
    }

    if (id === newid) {
        document.body.classList.add('video-active');
        player.play();
        return;
    }

    if (player) {
        player.removeEventListener('ended', to_start);
        player.remove();
        player = null;
    }

    id = newid;
    player = make_player();
    player.addEventListener('ended', to_start);
    container.appendChild(player);
    document.body.classList.add('video-active');
    player.play();
};

const close_video = () => {
    if (player) {
        player.pause();
    }
    document.body.classList.remove('video-active');
};


const video = () => {
    container = document.querySelector('.video-container');

    document.querySelectorAll('.story').forEach((x) => {
        x.addEventListener('click', open_video);
    });

    document.querySelectorAll('[data-type="close-video"]').forEach((x) => {
        x.addEventListener('click', close_video);
    });

    init_backstage(document.querySelector('.mouse-catcher'));
};

export default video;
