import axios from '../vendor/axios';

let current_file_id = undefined;
let hidden_input = undefined;


function set_new_file(event) {
    const self = event.target;
    if (self.dataset.fileId) {
        current_file_id = self.dataset.fileId;
        hidden_input.click();
    }
}


function upload_file(event) {
    const self = event.target;
    const token = document.querySelector('meta[name="csrf-token"]').content;
    let progress;

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': token,
        },
        onUploadProgress: function(progressEvent) {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            progress.style.width = `${percentCompleted}%`;
        }
    }

    let formData = new FormData();
    formData.append('file', self.files[0]);
    formData.append('file_id', current_file_id);

    let container = document.querySelector(`#file_item_${current_file_id}`);
    let title = container.querySelector('.doc-link-body');
    progress = container.querySelector('.upload-progress span');
    container.classList.add('upload_wait');

    axios.post('/replace_file', formData, config)
        .then((response) => {
            let data = response.data;
            if (data.status === 'ok') {
                title.innerHTML = data.filename;
            } else {
                alert(data.message);
            }
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            container.classList.remove('upload_wait');
            progress.style.width = '0%';
            progress = undefined;
        });

}



function remove_file(event) {
    const self = event.target;
    const token = document.querySelector('meta[name="csrf-token"]').content;
    if (self.dataset.fileId) {
        let cnfrm = confirm('Видалити файл?');
        if (cnfrm) {
            let formData = new FormData();
            formData.append('file_id', self.dataset.fileId);

            let container = document.querySelector(`#file_item_${self.dataset.fileId}`);
            container.classList.add('upload_wait');

            const config = {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRF-Token': token,
                }
            }

            axios.post('/remove_file', formData, config)
                .then((response) => {
                    let data = response.data;
                    if (data.status === 'ok') {
                        container.remove();
                        window.location.reload();
                    }
                }).catch((error) => {
                    console.error(error);
                });
        }
    }
}


const upload = () => {
    const form = document.querySelector('.upload-form');

    if (!form) {
        return;
    }

    const filename = form.querySelector('.upload-filename');

    form.addEventListener('submit', (event) => {
        let valid = true;
        if (form.brief.value === '') {
            form.brief[0].closest('.upload-ul').classList.add('upload-ul__error');
            valid = false;
        }
        if (form.upload.value === '') {
            form.upload.closest('.upload-file-btn').classList.add('upload-file-btn__error');
            valid = false;
        }
        if (!valid) {
            event.preventDefault();
        }
    });

    form.upload.addEventListener('change', () => {
        form.upload.closest('.upload-file-btn').classList.remove('upload-file-btn__error');
        filename.innerText = form.upload.files[0].name;
    })

    form.brief.forEach((x) => {
        x.addEventListener('click', () => {
            form.querySelector('.upload-ul').classList.remove('upload-ul__error');
        });
    })

    document.querySelectorAll('.event_remove').forEach((node) => {
        node.addEventListener('click', remove_file);
    });

    hidden_input = document.querySelector('#hidden_file');
    if (hidden_input) {
        document.querySelectorAll('.event_replace').forEach((node) => {
            node.addEventListener('click', set_new_file);
        });
        hidden_input.addEventListener('change', upload_file);
    }

}

export default upload;
