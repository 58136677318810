function getCoords(elem) {
    let box = elem.getBoundingClientRect();
    return {
        top: box.top + pageYOffset,
        left: box.left + pageXOffset
    };
}


const main_nav = () => {

    const main_nav_evt = (event) => {
        const href = event.target.href;
        const target = document.querySelector(`*[name="${href.split('#')[1]}"]`);
        if (target) {
            event.preventDefault();
            history.replaceState(null, document.title, href);
            try {
                document.body.classList.remove('show-nav');
                document.querySelector('.center-nav-li_with-subnav.opened').classList.remove('opened');
            } catch (e) {
                // empty
            }
            window.scrollTo({
                top: getCoords(target).top,
                behavior: 'smooth'
            });
        }
    };

    document.querySelectorAll('.center-subnav-link').forEach((x) => x.addEventListener('click', main_nav_evt));
}

export default main_nav;
