// const news_date_format = (date) => {
//     return date.split('-').reverse().join('/');
// }

const templates = (template, record) => {
    switch (template) {
        case 'news-list':
            return `<li class="main-news-item">
                <a href="/news/${record.id}" title="${record.name}" class="main-news-link">
                <span class="main-news-desc">
                    <span class="main-news-desc__data">${record.date}</span>
                    <span class="main-news-desc__title">${record.name}</span>
                </span>
                </a>
            </li>`
            break;
        case 'news-about':
            return `<div class="swiper-slide">
            <div class="news-about-us">
              <a href="/news/${record.id}" title="${record.name}" class="news-about-us-pic relative">
                <picture class="news-about-us-pic__image">
                  <source srcset="${record.images.small}" media="(max-width: 768px)" />
                  <source srcset="${record.images.middle}" media="(max-width: 1280px)" />
                  <source srcset="${record.images.big}" />
                  <img src="${record.images.middle}" alt="${record.name}" class="news-about-us-pic__image" />
                </picture>
              </a>
              <span class="news-about-us-source">
                <a href="${record.outer_link}" target="_blank" class="news-about-us-source__link">${record.outer_link_name}</a>
              </span>
              <a href="/news/${record.id}" title="${record.name}" class="news-about-us-desc">
                <span class="news-about-us-desc__title">${record.name}</span>
                <span class="news-about-us-desc__date">${record.long_date}</span>
              </a>
            </div>
            </div>`
            break;
        case 'study':
            return `<li class="lecture-item accordion-item faq-item">
                <div class="flex lecture-head accordion-title">
                  <div class="lecture-part-0">
                    <div class="lecture-date">${record.date}</div>
                    <div class="lecture-speaker">
                      <div class="lecture-t-head">Спікер:</div>
                      <div class="lecture-speaker-name">${record.speaker_name}</div>
                          <div class="lecture-speaker-whois">${record.speaker_who_is}</div>
                    </div>
                    <div class="lecture-photo">
                      <div class="lecture-photo-pic">
                        <img src="${record.image}">
                      </div>
                    </div>
                    <div class="lecture-preview">
                      <h5 class="lecture-t-head">Про що поговоримо:</h5>
                      <ul>
                        ${record.name}
                      </ul>
                    </div>
                  </div>
                  <!-- button class="faq-switch">Показати/Сховати</button -->
                </div>
                <div class="lecture-answer relative overflow-hidden accordion-body">
                  <div class="accordion-content">
                    <div class="lecture-part-1">
                      <div class="lecture-part-1-content">
                        ${record.text}
                      </div>
                    </div>
                  </div>
                </div>
              </li>`;
            break;
        default:
            return '';
    }
}

const print_data = (event) => {
    const [data, status, xhr] = event.detail;
    const target = event.target;
    const template = target.dataset.template;
    const box = document.querySelector(`[data-xhr-target="${template}"]`);

    let html = data.list.reduce((previousValue, item) => previousValue + templates(template, item), '');

    if (box && (html !== '')) {
        box.insertAdjacentHTML('beforeend', html);
    }

    if (data.next) {
        const link = target.href.substring(0, target.href.indexOf('page='));
        target.href = `${link}page=${data.next}`;
    } else {
        target.remove();
    }
}


const get_data = () => {
    document.querySelectorAll('[data-type="get-data"]').forEach((x) => {
        x.addEventListener('ajax:success', print_data)
    })
}

export default get_data;
