import debounce from 'lodash/debounce';
// import Swiper from 'swiper/bundle';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
const Swiper = SwiperCore.use([Navigation, Pagination]);

import customSlider from './custom-slider.js';

const TABLET = 768;
let swiper;
let container;
let custom;

const init_custom = () => {
    custom = new customSlider(container);
};

const init_swiper = () => {
    swiper = new Swiper(container, {
        slidesPerView: 'auto',
        spaceBetween: 16,

        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
};

const prize_slider = () => {

    container = document.querySelector('.swiper-prize-slider');

    if (!container) {
        return;
    }

    const window_switcher = () => {
        if (document.documentElement.clientWidth < TABLET) {
            if(!swiper) {
                init_swiper();
            }
            return;
        }

        if (!custom) {
            if (swiper) {
                swiper.destroy(true, true)
            }
            init_custom();
        }
    }


    window_switcher();
    window.addEventListener('resize', debounce(window_switcher, 50));

}

export default prize_slider;
