
const scroll_down = () => {
    window.scrollTo({
        top: window.pageYOffset + document.documentElement.clientHeight,
        behavior: 'smooth'
    });
};


const down_botton = () => {
    const btn = document.querySelector('.down-button');
    if (!btn) {
        return;
    }
    btn.addEventListener('click', scroll_down);
};


export default down_botton;
