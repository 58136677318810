import debounce from 'lodash/debounce';

const sticky_footer = () => {

    const main = document.querySelector('#main_content');
    const footer = document.querySelector('.footer');
    const spacer = document.querySelector('#footer_spacer');

    const set_sticky_position = () => {
        const fh = footer.offsetHeight;
        spacer.style.height = `${fh}px`;
        footer.style.marginTop = `-${fh}px`;
        main.style.position = 'sticky';
        main.style.top = `-${main.offsetHeight - document.documentElement.clientHeight}px`;
    }

    if (footer) {
        set_sticky_position();
        window.addEventListener('resize', debounce(set_sticky_position, 50));

        // Конфигурация observer (за какими изменениями наблюдать)
        const config = {
            attributes: false,
            childList: true,
            subtree: true
        };

        // Колбэк-функция при срабатывании мутации
        const callback = function(mutationsList, observer) {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    set_sticky_position();
                }
            }
        };

        // Создаём экземпляр наблюдателя с указанной функцией колбэка
        const observer = new MutationObserver(callback);

        // Начинаем наблюдение за настроенными изменениями целевого элемента
        observer.observe(main, config);

    }
}

export default sticky_footer;
