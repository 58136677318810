import axios from '../vendor/axios';

function add_error_label(node, form_type) {
    const parent = node.closest('.form-line');
    try {
        node.closest('.form-line').classList.add('input-error');
    } catch (e) {
        console.error(e);
    }

    // if (form_type === 'auth') {
    //     parent = node.closest('.auth-form-line');
    // } else if (form_type === 'register') {
    //     parent = node.closest('.ins-form-line');
    // }
    // try {
    //     parent.classList.add('form-input_error');
    // } catch(e) {
    //     // empty
    // }
}

function check_data(form) {
    const inputs = form.querySelectorAll('input[data-required="true"], textarea[data-required="true"]');
    let result = true;
    let re;
    let local;

    const form_type = form.dataset.type;

    inputs.forEach((input) => {
        if (input.dataset.regexp && input.dataset.regexp !== '') {
            re = new RegExp(input.dataset.regexp, 'i');
            local = re.test(input.value);
        } else {
            local = input.value !== '';
        }

        if (input.type === 'checkbox') {
            local = input.checked;
        }

        if (!local) {
            add_error_label(input, form_type);
        }

        if (input.dataset.equal) {
            let remote = form[input.dataset.equal];
            if (input.value !== remote.value) {
                add_error_label(input, form_type);
                add_error_label(remote, form_type);
                result = false;
            }
        }

        result = result && local;
    });

    return result;
}


function post_data(form) {
    const data = new FormData(form);
    const token = document.querySelector('meta[name="csrf-token"]').content;
    axios.defaults.headers.common['X-CSRF-Token'] = token;
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    return new Promise(function(resolve, reject) {
        axios.post(form.action, data).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            console.error(error);
        });
    });
}


function remove_input_labes() {
    document.querySelectorAll('input, textarea').forEach((input) => {
        let action = input.type === 'checkbox' ? 'click' : 'focus';
        input.addEventListener(action, (event) => {
            let self = event.target;
            let parent = self.closest('.input-error');
            if (parent) {
                parent.classList.remove('input-error');
            }

            if (self.placeholder) {
                self.placeholder = '';
            }
        });

        input.addEventListener('blur', (event) => {
            let self = event.target;
            if (self.dataset.placeholder && self.value === '') {
                self.placeholder = self.dataset.placeholder;
            }
        });
    });
}

function wait(form, waiting=true) {
    let button = form.querySelector('*[type="submit"]');
    if (!button) {
        return;
    }
    button.disabled = waiting;
}


export {
    check_data,
    post_data,
    remove_input_labes,
    wait
};
