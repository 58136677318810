
const show_hide_nav = () => {

    const show_nav = () => {
        document.body.classList.add('show-nav');
    };

    const hide_nav = () => {
        document.body.classList.remove('show-nav');
    };

    document.querySelectorAll('[data-event-type="show-nav"]').forEach((x) => x.addEventListener('click', show_nav));

    document.querySelectorAll('[data-event-type="hide-nav"]').forEach((x) => x.addEventListener('click', hide_nav));
}

export default show_hide_nav;
