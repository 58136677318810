
const left_nav_autohide = () => {
    if (document.documentElement.clientWidth < 1280) {
        return;
    }

    const body = document.body;

    window.addEventListener('scroll', () => {
        if (window.pageYOffset > (document.documentElement.clientHeight / 3)) {
            body.classList.remove('show-nav');
        } else {
            body.classList.add('show-nav');
        }
    });
    body.classList.add('show-nav');

    document.addEventListener('click', (event) => {
        if(!event.target.closest('.center-nav') && window.pageYOffset > (document.documentElement.clientHeight / 3)){
            body.classList.remove('show-nav');
        }
    });
};


export default left_nav_autohide;
