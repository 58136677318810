
class customSlider {
    constructor(selector) {
        this.box = selector;
        if (!this.box) {
            return;
        }
        this.btn_next = this.box.querySelector('.swiper-button-next');
        this.btn_prev = this.box.querySelector('.swiper-button-prev');
        this.currentIndex = 0;
        this.slides = this.box.querySelectorAll('.swiper-slide');
        this.max = this.slides.length - 1;
        this.prefix = 'slide-';
        this.box.classList.add(`${this.prefix}${this.currentIndex}`);
        this.check_btns();

        this.btn_next.addEventListener('click', this.slideNext.bind(this));
        this.btn_prev.addEventListener('click', this.slidePrev.bind(this));

        // for (let slide of this.slides) {
        //     slide.style.height = slide.offsetHeight + 'px';
        // }

        // this.slides.forEach(x => x.addEventListener('click', (event) => {
        //     const target = parseInt(event.currentTarget.dataset.targetSlide, 10);
        //     this.gotoSlide.bind(this);
        // }));

        this.slides.forEach(x => x.addEventListener('click', this.gotoSlide.bind(this)));

    }

    disable(node) {
        node.classList.add('disabled');
    }

    enable(node) {
        node.classList.remove('disabled');
    }

    check_btns() {
        if (this.currentIndex == 0) {
            this.disable(this.btn_prev);
        }
        if (this.currentIndex == this.max) {
            this.disable(this.btn_next);
        }
        if (this.currentIndex > 0) {
            this.enable(this.btn_prev);
        }
        if (this.currentIndex < this.max) {
            this.enable(this.btn_next);
        }
    }

    gotoSlide (index) {

        if (typeof index === 'object') {
            index = parseInt(index.currentTarget.dataset.targetSlide, 10);
        }

        if (index < 0 || index > this.max) {
            return;
        }
        this.box.classList.remove(`${this.prefix}${this.currentIndex}`);
        this.currentIndex = index;
        this.box.classList.add(`${this.prefix}${this.currentIndex}`);
        this.check_btns();
    }

    slideNext() {
        this.gotoSlide (this.currentIndex + 1);
    }

    slidePrev() {
        this.gotoSlide (this.currentIndex - 1);
    }
}

export default customSlider;
