// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import auth from './forms/auth.js';
import message_form from './forms/message.js';
import profile from './forms/profile.js';
import upload from './forms/upload.js';
import { remove_input_labes } from './forms/utils.js';

import stickynav from './sticky-nav.js';
import sticky_footer from './sticky-footer.js';
import show_hide_nav from './show-hide-nav.js';
import nav_dropdown from './nav-dropdown.js';
import main_nav from './main-nav.js';
import prize_slider from './prize-slider.js';
import video from './video.js';
import hover_emit from './hover-emit.js';
import news_slider from './news-slider.js';
import down_botton from './down-button.js';
import left_nav_autohide from './left_nav_autohide.js';

// Getters
import get_data from './get-data.js';


document.addEventListener('DOMContentLoaded', () => {
    auth();
    remove_input_labes();
    profile();
    stickynav();
    show_hide_nav();
    nav_dropdown();
    main_nav();
    prize_slider();
    message_form();
    video();
    down_botton();
    left_nav_autohide();

    hover_emit();
    news_slider();

    get_data();

    upload();

    let user_widget = document.querySelector('.user-widget');
    if (user_widget) {
        user_widget.addEventListener('click', function(){
            this.classList.add('opened');
        });

        document.addEventListener('click', (event) => {
            const target = event.target;
            if (target.closest('.user-widget')
                    || target.classList.contains('user-widget')) {
                return;
            }
            user_widget.classList.remove('opened');
        });
    }

    if(document.querySelector('.accordion')) {
        import('./accordion').then( fn => fn.init() );
    }

    // main promo
    if(document.querySelector('.main-preload')) {
        import('./promo.js').then( fn => fn.init() );
    }
});

window.addEventListener('load', () => {
    sticky_footer();
});
