
const hover_emit = () => {
    document.querySelectorAll('[data-hover-emit]').forEach((x) => {
        x.addEventListener('mouseover', function(){
            try {
                this.closest(`.${this.dataset.hoverEmit}`).classList.add('em-hover');
            } catch(e) {
                // empty
            }
        });
        x.addEventListener('mouseout', function(){
            try {
                this.closest(`.${this.dataset.hoverEmit}`).classList.remove('em-hover');
            } catch(e) {
                // empty
            }
        });
    });

};


export default hover_emit;
