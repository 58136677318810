import IMask from '../vendor/imask';
import { check_data, post_data, wait } from './utils';
// import { show_popup } from '../popup';


let container;


function change_slide(slide_classname) {
    container.className = `grid grid-auth-container show-${slide_classname}`;
}


function auth() {

    container = document.querySelector('.grid-auth-container');
    if (!container) {
        return;
    }

    document.querySelectorAll('*[data-link-type="change-auth-slide"]').forEach((elem) => {
        elem.addEventListener('click', (event) => {
            event.preventDefault();
            change_slide(event.target.dataset.slideTarget);
        });
    });

    document.querySelectorAll('.auth-pass-switch').forEach((elem) => {
        elem.addEventListener('click', (event) => {
            try {
               let input = event.target.parentNode.querySelector('input');
               input.type = input.type === 'text' ? 'password' : 'text';
            } catch(e) {
                // empty
            }
        })
    });

    document.querySelectorAll('*[data-type="auth"]').forEach((elem) => {
        elem.addEventListener('submit', async (event) => {
            event.preventDefault();
            let self = event.target;
            let stderr, stdout;
            if (check_data(self)) {
                wait(self, true);
                let result = await post_data(self);
                if (result.status === 'ok') {
                    if (result.hasOwnProperty('redirect_to')) {
                        window.location = result.redirect_to;
                        return;
                    }

                    if (result.hasOwnProperty('slide')) {
                        const msg = document.querySelector(`.tab-${result.slide} .auth-text` );
                        msg.innerHTML = result.message;
                        change_slide(result.slide);
                        return;
                    }

                } else if (result.status === 'fail') {
                    stderr = self.querySelector('.form-line-msg');
                    stderr.innerHTML = result.message;
                }
                wait(self, false);
            }
        });
    });

    document.querySelectorAll('input[name="tel"]').forEach((node) => {
        const maskOptions = {
            mask: '+000 (00) 000-00-00',
            lazy: true,
            placeholderChar: '_',
        };
        IMask(node, maskOptions);
    });

    document.querySelectorAll('*[data-link-type="show-auth"]').forEach((elem) => {
        elem.addEventListener('click', (event) => {
            event.preventDefault();
            document.body.classList.add('show-auth-box');
            // show_popup('auth');
        });
    });

    document.querySelectorAll('*[data-link-type="hide-auth"]').forEach((elem) => {
        elem.addEventListener('click', (event) => {
            event.preventDefault();
            document.body.classList.remove('show-auth-box');
        });
    });
}

export default auth;
